<template>
  <Home>
    <template slot="alerts">
      <h3>{{ $t('menu.homeDemo') }}</h3>
    </template>
    <div class="table_list_box" v-if="checkRegisterStep">
      <div class="table_item_box" v-if="$platform.getTradingPlatform(regulator).mt4">
        <div class="title clearfix">
          <img src="@/assets/images/home_mt4_logo.png" alt class="fl" />
          <div class="btn_box">
            <router-link :to="{ name: 'register' }" class="el-button is-plain text-uppercase" data-testid="liveButton4">
              {{ $t('openDemoAdditAcc.liveButton') }}
            </router-link>
          </div>
        </div>
        <div class="table_box">
          <el-table>
            <el-table-column :label="$t('common.column.accNum')"></el-table-column>
            <el-table-column :label="$t('common.column.type')"></el-table-column>
            <el-table-column :label="$t('common.column.currency')"></el-table-column>
            <el-table-column :label="$t('common.column.equity')"></el-table-column>
            <el-table-column :label="$t('common.column.leverage')" min-width="90"></el-table-column>
            <el-table-column :label="$t('common.column.status')"></el-table-column>
            <el-table-column :label="$t('common.column.resetMT4PW')"></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="table_item_box" v-if="$platform.getTradingPlatform(regulator).mt5">
        <div class="title clearfix">
          <img src="@/assets/images/home_mt5_logo.png" alt class="fl" />
          <div class="btn_box">
            <router-link :to="{ name: 'register' }" class="el-button is-plain text-uppercase" data-testid="liveButton5">
              {{ $t('openDemoAdditAcc.liveButton') }}
            </router-link>
          </div>
        </div>
        <div class="table_box">
          <el-table>
            <el-table-column :label="$t('common.column.accNum')"></el-table-column>
            <el-table-column :label="$t('common.column.type')"></el-table-column>
            <el-table-column :label="$t('common.column.currency')"></el-table-column>
            <el-table-column :label="$t('common.column.equity')"></el-table-column>
            <el-table-column :label="$t('common.column.leverage')" min-width="90"></el-table-column>
            <el-table-column :label="$t('common.column.status')"></el-table-column>
            <el-table-column :label="$t('common.column.resetMT5PW')"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="table_list_box">
      <div class="table_item_box" v-if="$platform.getTradingPlatform(regulator).mt4">
        <div class="title clearfix">
          <img src="@/assets/images/home_mt4_logo.png" alt />
          <div class="btn_box ms-3">
            <router-link
              :to="{ name: 'openDemoAccount' }"
              class="el-button text-uppercase el-button--primary el-button--mini"
              data-testid="openDemoAdditAcc4"
            >
              {{ $t('openDemoAdditAcc.button') }}
            </router-link>
          </div>
        </div>
        <div class="table_box">
          <el-table :data="mt4AccountTableData" :cell-class-name="cell">
            <el-table-column :label="$t('common.column.accNum')" prop="accNum" min-width="130"></el-table-column>
            <el-table-column
              show-overflow-tooltip
              class="text-transform-none"
              :label="$t('common.column.server')"
              prop="server"
              min-width="180"
            ></el-table-column>
            <el-table-column :label="$t('common.column.type')">
              <template slot-scope="scope">
                {{ $platform.accountTypeMaps(regulator)[scope.row.accMt4Type] }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.currency')" prop="currency" min-width="100"></el-table-column>
            <el-table-column :label="$t('common.column.equity')" min-width="120">
              <template slot-scope="scope">{{ scope.row.equity | formatNumber }}</template>
            </el-table-column>
            <el-table-column :label="$t('common.column.leverage')" min-width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.status !== 'expired'">
                  {{ scope.row.leverage }} : 1
                  <div class="d-inline-block" v-if="scope.row.leverage">
                    <a>
                      <i
                        class="table-icon el-icon-caret-bottom text-magenta"
                        @click.stop.prevent="leverageDemoClick(scope.row)"
                        data-testid="leverageMt4"
                      ></i>
                    </a>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.status')" prop="status" min-width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.status === 'active'" class="text-green">
                  {{ scope.row.status | accStatus }}
                </div>
                <div v-if="scope.row.status === 'expired'" class="text-magenta">
                  <i>{{ scope.row.status | accStatus }}</i>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.resetBal')" prop="status" min-width="110">
              <template slot-scope="scope">
                <div v-if="scope.row.status === 'active'" class="reset_balance">
                  <a @click.stop.prevent="resetBalClick(scope.row)" data-testid="resetBal">$</a>
                </div>
                <div v-if="scope.row.status === 'expired'" class="text-magenta">-</div>
              </template>
            </el-table-column>
            <component :is="tradingViewComponent" :mtServer="4"></component>
            <el-table-column :label="$t('common.column.resetMT4PW')" min-width="130">
              <template slot-scope="scope">
                <a
                  v-if="scope.row.status === 'active'"
                  @click.stop.prevent="handleLock(scope.$index, scope.row)"
                  data-testid="resetMT4PW"
                >
                  <img src="@/assets/images/lock_icon.png" alt />
                </a>
                <i v-else>-</i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="table_item_box" v-if="$platform.getTradingPlatform(regulator).mt5">
        <div class="title clearfix">
          <img src="@/assets/images/home_mt5_logo.png" alt />
          <div class="btn_box ms-3">
            <router-link
              :to="{ name: 'openDemoAccount' }"
              class="el-button text-uppercase el-button--primary el-button--mini"
              data-testid="openDemoAdditAcc5"
            >
              {{ $t('openDemoAdditAcc.button') }}
            </router-link>
          </div>
        </div>
        <div class="table_box">
          <el-table :data="mt5AccountTableData" :cell-class-name="cell">
            <el-table-column :label="$t('common.column.accNum')" prop="accNum" min-width="130"></el-table-column>
            <el-table-column
              show-overflow-tooltip
              class="text-transform-none"
              :label="$t('common.column.server')"
              prop="server"
              min-width="180"
            ></el-table-column>
            <el-table-column :label="$t('common.column.type')">
              <template slot-scope="scope">
                {{ $platform.accountTypeMaps(regulator)[scope.row.accMt4Type] }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.currency')" prop="currency" min-width="100"></el-table-column>
            <el-table-column :label="$t('common.column.equity')" min-width="120">
              <template slot-scope="scope">{{ scope.row.equity | formatNumber }}</template>
            </el-table-column>
            <el-table-column :label="$t('common.column.leverage')" min-width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.status !== 'expired'">
                  {{ scope.row.leverage }} : 1
                  <div class="d-inline-block" v-if="scope.row.leverage">
                    <a>
                      <i
                        class="table-icon el-icon-caret-bottom text-magenta"
                        @click.stop.prevent="leverageDemoClick(scope.row)"
                        data-testid="leverageMt5"
                      ></i>
                    </a>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.status')" prop="status" min-width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.status === 'active'" class="text-green">
                  {{ scope.row.status | accStatus }}
                </div>
                <div v-if="scope.row.status === 'expired'" class="text-magenta">
                  <i>{{ scope.row.status | accStatus }}</i>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.column.resetBal')" prop="status" min-width="110">
              <template slot-scope="scope">
                <div v-if="scope.row.status === 'active'" class="reset_balance">
                  <a @click.stop.prevent="resetBalClick(scope.row)" data-testid="resetBal">$</a>
                </div>
                <div v-if="scope.row.status === 'expired'" class="text-magenta">-</div>
              </template>
            </el-table-column>
            <component :is="tradingViewComponent" :mtServer="5"></component>
            <el-table-column :label="$t('common.column.resetMT5PW')" min-width="130">
              <template slot-scope="scope">
                <a
                  v-if="scope.row.status === 'active'"
                  @click.stop.prevent="handleLock(scope.$index, scope.row)"
                  data-testid="resetMT5PW"
                >
                  <img src="@/assets/images/lock_icon.png" alt />
                </a>
                <i v-else>-</i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <template slot="components">
      <!-- CHANGE LEVERAGE 弹框开始 -->
      <ChangeLeverage
        :changeLeverageVisible.sync="changeLeverageVisible"
        :leverageConfirmVisible.sync="leverageConfirmVisible"
        :showTNC="false"
        :leverageChangeSubmitSuccess.sync="leverageChangeSubmitSuccess"
        :leverageChangeSubmitFail.sync="leverageChangeSubmitFail"
        :leverageChangeSubmitReject.sync="leverageChangeSubmitReject"
        :leverageChangeAuditSuccess.sync="leverageChangeAuditSuccess"
        :leverageChangeSubmitTimeOut.sync="leverageChangeSubmitTimeOut"
        @resetTable="getDemo"
        method="demo"
      ></ChangeLeverage>

      <!-- CHANGE LEVERAGE confirm 弹框开始 -->
      <Common :homeVisible.sync="leverageConfirmVisible">
        <div class="dialog_body" v-if="leverageChangeSubmitSuccess">
          <i class="icon el-icon-circle-check text-green"></i>
          <p
            v-html="
              leverageChangeAuditSuccess
                ? $t('home.leverage.changeLeverageAutoComplete')
                : $t('home.leverage.changeLeverageConfirm')
            "
          ></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitFail">
          <i class="icon el-icon-circle-close text-magenta"></i>
          <p v-html="$t('home.leverage.changeLeverageFail')"></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitReject">
          <i class="icon el-icon-circle-close text-magenta"></i>
          <p v-html="$t('home.leverage.changeLeverageReject')"></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitTimeOut">
          <i class="icon el-icon-circle-close text-magenta"></i>
          <p v-html="$t('home.leverage.changeLeverageDefault', { supportEmail: GLOBAL_CONTACT_EMAIL })"></p>
        </div>
      </Common>
      <!-- CHANGE LEVERAGE confirm 弹框结束 -->

      <!-- 点击小锁弹框开始 -->
      <Password
        :passwordVisible.sync="passwordVisible"
        :forgotPwdVisible.sync="forgotPwdVisible"
        :number="number"
        :uuid="uuid"
        @forgotPwdClick="forgotPwdClick"
      ></Password>
      <!-- 点击小锁弹框结束 -->

      <!-- 点击 CHANGE PASSWORD  表单弹框开始 -->
      <ChangePassword
        :changePasswordVisible.sync="changePasswordVisible"
        :pwdConfirmVisible.sync="pwdConfirmVisible"
        :number="number"
        :isDemo="isDemo"
        @forgotPwdClick="forgotPwdClick"
      ></ChangePassword>
      <!-- 点击 CHANGE PASSWORD  表单弹框结束 -->

      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->
      <Common :homeVisible.sync="pwdConfirmVisible">
        <i class="icon el-icon-circle-check text-green"></i>
        <p v-html="$t('home.pw.confirm')"></p>
      </Common>
      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->

      <!-- FORGOT PASSWORD 开始 -->
      <Common :homeVisible.sync="forgotPwdVisible">
        <i class="icon el-icon-circle-check text-green"></i>
        <div class="mt-3" v-html="$t('home.pw.email')"></div>
      </Common>
      <!-- FORGOT PASSWORD 结束 -->

      <!-- 点击 RESET BALANCE 开始 -->
      <ResetBalance :resetBalVisible.sync="resetBalVisible" :number="number" @resetTable="getDemo"> </ResetBalance>
      <!-- 点击 RESET BALANCE 结束 -->
    </template>
  </Home>
</template>

<script>
import Home from '@/components/template/Home';
import ChangeLeverage from '@/components/home/ChangeLeverage';
import Common from '@/components/home/Common';
import { apiHomeGet_demo_accs, apiReq_reset_mt4_password } from '@/resource';
import changeLeverageMixins from '@/mixins/page/home/changeLeverage';
import Password from '@/components/home/Password';
import ChangePassword from '@/components/home/ChangePassword';
import ResetBalance from '@/components/home/ResetBalance';

const SERVER_INDEX_COLUMN = 1;

export default {
  components: { Home, ChangeLeverage, Common, Password, ChangePassword, ResetBalance },
  mixins: [changeLeverageMixins],
  data() {
    return {
      uuid: this.$store.state.common.uuid,
      mt4AccountTableData: [],
      mt5AccountTableData: [],
      allMetaDemoAccounts: [],
      passwordVisible: false,
      changePasswordVisible: false,
      pwdConfirmVisible: false,
      forgotPwdVisible: false,
      resetBalVisible: false,
      number: null,
      isDemo: true
    };
  },
  mounted() {
    this.getDemo();
    console.log('Home Demo');
  },
  methods: {
    getDemo() {
      apiHomeGet_demo_accs().then(resp => {
        this.allMetaDemoAccounts = resp.data.data || [];
        this.formatAccounts();
      });
    },
    formatAccounts() {
      this.mt4AccountTableData = [];
      this.mt5AccountTableData = [];
      this.allMetaDemoAccounts.map(m => {
        m.platform === '4' ? this.mt4AccountTableData.push(m) : this.mt5AccountTableData.push(m);
      });
    },
    handleLock(index, row) {
      this.passwordVisible = true;
      this.number = parseInt(row.accNum);
    },
    forgotPwdClick() {
      this.sendResetPasswordEmail().then(result => {
        let vm = this;
        if (result) {
          this.passwordVisible = false;
          this.forgotPwdVisible = true;
        } else {
          this.$message({
            message: vm.$t('home.pw.error'),
            type: 'error'
          });
        }
      });
    },
    resetBalClick(row) {
      this.resetBalVisible = true;
      this.number = parseInt(row.accNum);
    },
    sendResetPasswordEmail() {
      const baseUrl = location.protocol + '//' + location.hostname;
      return apiReq_reset_mt4_password({
        userId: this.uuid,
        mtAccount: this.number,
        baseUrl: baseUrl,
        isDemo: this.isDemo
      }).then(resp => {
        if (resp.data.code === 0) {
          if (resp.data.data) {
            console.log('The reset password email is sent out');
            return Promise.resolve(true);
          } else {
            console.log('failed to sent out the reset password');
          }
        } else {
          console.log('Errors happened when applying for the reset password emails');
        }
        return Promise.resolve(false);
      });
    },
    cell({ columnIndex }) {
      if (columnIndex === SERVER_INDEX_COLUMN) {
        return 'text-transform-none';
      }
    }
  },
  computed: {
    checkRegisterStep() {
      return this.$store.state.common.registerStep < 5;
    },
    tradingViewComponent() {
      return () => this.$platform.componentLoader(this.regulator, 'TradingViewColumn', true).component;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home.scss';
#home .table_item_box {
  margin-bottom: 30px !important;
  .table_box {
    padding-bottom: 0;
  }
}
</style>
