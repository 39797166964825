<template>
  <div class="reset_balance_dialog">
    <vDialog :visible.sync="visible" :visibleFooter="true">
      <el-form label-position="top" :model="formLabelAlign" ref="resetBalanceForm" :rules="resetBalanceRules">
        <el-form-item :label="$t('common.field.accNum')">
          <span class="highlight-data">{{ number }}</span>
        </el-form-item>
        <el-form-item :label="$t('common.field.resetBalTo')" prop="resetBalTo">
          <el-input autocomplete="false" v-model="formLabelAlign.resetBalTo" data-testid="resetBalTo"></el-input>
        </el-form-item>
        <div class="btn_box">
          <el-button @click="resetBalConfirmClick" data-testid="confirm">
            {{ $t('common.button.confirm') }}
          </el-button>
          <el-button plain @click="disableResetBalanceVisible(false)" data-testid="cancel">
            {{ $t('common.button.cancel') }}
          </el-button>
        </div>
      </el-form>
    </vDialog>
  </div>
</template>

<script>
import vDialog from './vDialog';
import { apiupdate_balance_demo } from '@/resource';

export default {
  name: 'resetBalance',
  props: {
    resetBalVisible: Boolean,
    number: Number,
    resetBalConfirmVisible: Boolean
  },
  components: { vDialog },
  data() {
    const validateResetBalTo = (rule, value, callback) => {
      this.formLabelAlign.resetBalToVerification = false;
      console.log(value);
      if (value == '' || !checkNumber(value) || value < 1 || value > 1000000) {
        callback(new Error(this.$t('common.formValidation.resetBalAmt')));
      } else {
        this.formLabelAlign.resetBalToVerification = true;
      }
    };
    const checkNumber = value => {
      let reg = new RegExp('^[0-9]+$');
      return reg.test(value);
    };
    return {
      visible: false,
      formLabelAlign: {
        resetBalTo: '',
        resetBalToVerification: false
      },
      resetBalanceRules: {
        resetBalTo: [{ required: true, validator: validateResetBalTo, trigger: 'blur' }]
      }
    };
  },
  watch: {
    resetBalVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.disableResetBalanceVisible(bool);
    }
  },
  methods: {
    resetBalConfirmClick() {
      if (this.formLabelAlign.resetBalToVerification) {
        this.updateBalanceDemo();
      }
    },
    disableResetBalanceVisible(bool) {
      this.$nextTick(() => {
        this.$refs.resetBalanceForm.resetFields();
        this.formLabelAlign.resetBalToVerification = false;
        this.$emit('update:resetBalVisible', bool);
      });
    },
    updateBalanceDemo() {
      apiupdate_balance_demo({
        metaTraderLogin: this.number,
        targetBalance: this.formLabelAlign.resetBalTo
      })
        .then(resp => {
          this.$emit('resetTable');
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.disableResetBalanceVisible(false);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/changeLeverage.scss';
</style>
